<template>
  <div class="backup-page">
    <div
      v-if="loading"
      class="d-flex justify-content-center mb-1 mt-5"
    >
      <b-spinner label="Loading..." />
    </div>
    <b-card
      v-else
    >
      <b-row>
        <b-col>
          <b-row>
            <b-col class="card-title">
              Backups List
            </b-col>
            <b-col>
              <b-button
                class="float-right"
                @click="new_backup"
              >
                <feather-icon icon="PlusIcon" />
                New Backup
              </b-button>
            </b-col>
          </b-row>
          <div
            v-if="downloadLoading"
            class="d-flex justify-content-center mb-1 mt-5"
          >
            <b-spinner label="Loading..." />
          </div>
          <new-table
            :export-file-name="exportFileName"
            :columns="columns"
            :items="items"
            table-name="maintenance-backup-list"
            @download="download"
            @remove="remove"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip, BCol, BRow, BSpinner, BButton,
} from 'bootstrap-vue'
import NewTable from '@/views/new-table.vue'
import Moment from 'moment'

export default {
  components: {
    BButton,
    BCard,
    BSpinner,
    BCol,
    BRow,
    NewTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      downloadLoading: false,
      moment: Moment,
      loading: false,
      serviceLocator: null,
      oldFilter: '',
      isAddNewSlideSidebarActive: false,
      exportFileName: `backup_List_${Moment(new Date()).format('YYYY-MM-DD')}`,
      items: [],
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'serviceName', label: 'Service Locator', sortable: true },
        { key: 'ratingsAvg', label: 'Rating', sortable: true },
        { key: 'ServiceLocatorAddress', label: 'Address', sortable: true },
        { key: 'PhoneNumber', label: 'Phone', sortable: true },
        { key: 'country', label: 'Country', sortable: true },
        { key: 'actions', label: 'Actions', sortable: true },
      ],
      columns: [
        {
          label: 'Backup Date',
          data: 'backup_data',
          filterable: false,
          custom_template: {
            template_name: 'maintenance.backup.list.backup_date',
          },
          calculateCellValue(data) {
            return data.backup
          },
        },
        {
          label: 'Actions',
          data: 'actions',
          filterable: false,
          sortable: false,
          custom_template: {
            template_name: 'maintenance.backup.list.actions',
          },

        },
      ],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    new_backup() {
      this.loading = true
      this.$http.post(this.$resources.maintenance.backups.create).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.items = data.backups
          this.loading = false
        }
      })
    },
    download(url) {
      window.open(url)
    },
    remove(file) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want delete ${file} backup?`, {
          headerBgVariant: 'danger',
          okVariant: 'danger',
        })
        .then(value => {
          if (value) {
            this.loading = true
            this.$http.delete(this.$resources.maintenance.backups.delete, {
              params: {
                file,
              },
            }).then(() => {
              this.$notify({ type: 'success', title: 'Remove backup', text: 'Backup removed successfully' })
              this.loading = false
              this.load()
            })
          }
        })
    },
    load() {
      this.loading = true
      this.$http.get(this.$resources.maintenance.backups.list).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.items = data.backups
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
 .backup-page {
  .card {
    .card-body {
      min-height: 900px;
    }
  }
 }
</style>
